<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_selling_rate')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-autocomplete
                            v-model="editedData.fromCurrency"
                            :error-messages="fromCurrencyErrors"
                            :label="$t('from_currency')"
                            required
                                    dense
                            outlined
                            :items="selectData.destinations"
                            @input="$v.editedData.fromCurrency.$touch()"
                            @blur="$v.editedData.fromCurrency.$touch()"
                            item-text="value"
                            item-value="id"
                            @change="changeData"
                    ></v-autocomplete>

                    <v-autocomplete
                            v-model="editedData.toCurrency"
                            required
                            :label="$t('to_currency')"
                            :items="destinations"
                            item-text="value"
                                    dense
                            outlined
                            item-value="id"
                            :error-messages="toCurrencyErrors"
                            @input="$v.editedData.toCurrency.$touch()"
                            @blur="$v.editedData.toCurrency.$touch()"
                    ></v-autocomplete>
                    <v-text-field
                            v-model="editedData.sellingRate"
                            :error-messages="sellingRateErrors"
                            type="number"
                            dense
                            outlined
                            :label="$t('selling_rate')"
                            required
                            @input="$v.editedData.sellingRate.$touch()"
                            @blur="$v.editedData.sellingRate.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required, maxLength} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            fromCurrencyErrors() {
                const errors = [];
                if (!this.$v.editedData.fromCurrency.$dirty) return errors;
                !this.$v.editedData.fromCurrency.required &&
                    errors.push(`${this.$t("from_currency")} ${this.$t("is_required")}`);
                !this.$v.editedData.fromCurrency.maxLength && errors.push('From must be at most 3 characters long')

                return errors;
            },
            toCurrencyErrors() {
                const errors = [];
                if (!this.$v.editedData.toCurrency.$dirty) return errors;
                !this.$v.editedData.toCurrency.required &&
                    errors.push(`${this.$t("to_currency")} ${this.$t("is_required")}`);
                !this.$v.editedData.toCurrency.maxLength && errors.push('To must be at most 3 characters long')
                return errors;
            },
            sellingRateErrors() {
                const errors = [];
                if (!this.$v.editedData.sellingRate.$dirty) return errors;
                !this.$v.editedData.sellingRate.required &&
                    errors.push(`${this.$t("selling_rate")} ${this.$t("is_required")}`);
                return errors;
            }
        },
        data() {
            return {
                destinations: [],
                selectData: {
                    destinations: [],
                    selectedValue: null
                },
                province: [],
                show: false,
                updated: false,
                editedData: {
                    fromCurrency: "",
                    toCurrency: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                fromCurrency: {required, maxLength: maxLength(3)},
                toCurrency: {required, maxLength: maxLength(3)},
                sellingRate: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.formData();
            this.$forceUpdate();
        },
        methods: {
            formData() {
                axios.get('Shared/GetCurrencyList').then(response => {
                    this.selectData.destinations = response.data
                }).catch(err => {
                    console.log(err)
                })
            },
            changeData(val) {
                this.destinations = this.selectData.destinations.filter(destination => destination.value !== val);
                console.log(this.selectData.destinations)
            },
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        fromCurrency: this.editedData.fromCurrency,
                        toCurrency: this.editedData.toCurrency,
                        sellingRate: parseInt(this.editedData.sellingRate)
                    };
                    axios.post("NRBExchange/InsertIntoSellingExchangeRateAsync", param).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Selling Rate added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.fromCurrency = "";
                this.editedData.toCurrency = "";
            }
        }
    };
</script>

<style scoped>
</style>