<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>
        <v-tabs>
            <v-tab href="#tab-selling-rate">{{$t('selling_rate')}}</v-tab>
                <v-tab href="#tab-buying-rate">{{$t('buying_rate')}}</v-tab>
            <v-tab-item
                    value="tab-selling-rate"
            >
                <sellingRate></sellingRate>
            </v-tab-item>
            <v-tab-item
                    value="tab-buying-rate"
            >
                <buyingRate></buyingRate>
            </v-tab-item>

        </v-tabs>
    </v-content>
</template>

<script>
    import sellingRate from '../SellingRate/List'
    import buyingRate from '../BuyingRate/List'

    export default {
        name: "Exchange Services",
        components: {
            sellingRate,
            buyingRate
        },
        data() {
            return {
                snackbar: false,
                success: false,
            };
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('settings'),
                        disabled: false,
                        to: 'Income-GenerationSettings',
                        exact: true

                    },
                    {
                        text: this.$t('exchange_rate'),
                        disabled: true,
                    },
                ]
            }
        },
        mounted() {

        },
        methods: {}
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }
</style>